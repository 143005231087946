import Vue from 'vue';

let _LOADER = null

export function showLoading() {
  hideLoading()
  _LOADER = Vue.$loading.show({loader: 'dots', color: '#5226d4', backgroundColor: '#ffffff'})
}

export function hideLoading() {
  if (_LOADER != null) {
    try {
      _LOADER.hide()
    } finally {
      _LOADER = null
    }
  }
}
