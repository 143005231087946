import axios from 'axios'
import {hideLoading, showLoading} from './util/loading.utils'
import {getNumericSecurityCode, showBlockedUntil, showRetriesLeft} from './util/authorization-code.utils'


const WS_URL_PROD = "https://ws.olimpopay.com.br/api/v1"
const WS_URL_DEV = "http://localhost:8080/api/v1"

function getWsUrl() {
  if (window.location.href.includes('olimpopay.com.br')) {
    return WS_URL_PROD
  }
  return WS_URL_DEV
}

function setLocalStorage(response) {
  localStorage.setItem('ut', response.headers['ut'])
  localStorage.setItem('urt', response.headers['urt'])
  localStorage.setItem('n', response.headers['n'])
  localStorage.setItem('name', response.data.name)
  localStorage.setItem('socialNumberDisplay', response.data.socialNumberDisplay)
  localStorage.setItem('username', response.data.username)
  localStorage.setItem('usernameDisplay', response.data.usernameDisplay)
  localStorage.setItem('email', response.data.email)
  localStorage.setItem('personType', response.data.personType)
  localStorage.setItem('docsOk', response.data.docsOk)
  localStorage.setItem('roles', JSON.stringify(response.data.roles))
  localStorage.setItem('avatarBase64', response.data.avatarBase64)
  localStorage.setItem('avatarFileType', response.data.avatarFileType)
}


export function signin(username, password, recaptchaToken, handlerThen, handlerCatch) {
  postWs("/auth/signin",
    false,
    null,
    {username: username, password: password, recaptchaToken: recaptchaToken},
    function (response) {
      setLocalStorage(response)
      handlerThen(response)
    },
    function (error) {
      localStorage.clear()
      handlerCatch(error)
    })
}

export function signup(username, password, email, recaptchaToken, handlerThen, handlerCatch, codeRecommendation) {
  postWs("/auth/signup",
    false,
    null,
    {
      username: username,
      password: password,
      email: email,
      recaptchaToken: recaptchaToken,
      codeRecommendation: codeRecommendation
    },
    handlerThen,
    handlerCatch
  )
}

export function failWs(error) {
  localStorage.clear()
  location.href = "/#/"
  if (error) {
    console.error(error)
  }
}

function refresh(path, loading, method, headers, isAuthenticated, params, body, handlerThen, handlerCatch) {
  axios({
    url: getWsUrl() + "/auth/refresh-token",
    method: "GET",
    headers: {
      urt: localStorage.getItem('urt')
    }
  }).then(response => {
    setLocalStorage(response)
    callWs(path, loading, method, headers, isAuthenticated, params, body, handlerThen, handlerCatch)
  }).catch(error => {
    localStorage.clear()
    location.reload()
  })
}

export function getWs(path, isAuthenticated, params, handlerThen, handlerCatch, requiresNumericCode) {
  callWs(path, true, "GET", null, isAuthenticated, params, null, handlerThen, handlerCatch, requiresNumericCode)
}

export function putWs(path, isAuthenticated, params, handlerThen, handlerCatch, requiresNumericCode) {
  callWs(path, true, "PUT", null, isAuthenticated, params, null, handlerThen, handlerCatch, requiresNumericCode)
}

export function postWs(path, isAuthenticated, params, body, handlerThen, handlerCatch, requiresNumericCode) {
  callWs(path, true, "POST", null, isAuthenticated, params, body, handlerThen, handlerCatch, requiresNumericCode)
}

export function callWs(path, loading, method, headers, isAuthenticated, params, body, handlerThen, handlerCatch, requiresNumericCode) {
  if (requiresNumericCode) {
    getNumericSecurityCode().then(code => {
      if (code != null) {
        let allHeaders = {}
        allHeaders['c'] = code
        if (headers) {
          Object.assign(allHeaders, headers)
        }
        callWs(path, loading, method, allHeaders, isAuthenticated, params, body, handlerThen, handlerCatch, false)
      } else if (handlerCatch) {
        handlerCatch()
      } else {
        failWs("Empty CODE field!")
      }
    });
    return
  }

  if (loading) {
    showLoading()
  }
  let allHeaders = {}
  if (isAuthenticated) {
    allHeaders['ut'] = localStorage.getItem('ut')
  }
  if (headers) {
    Object.assign(allHeaders, headers)
  }
  axios({
    url: getWsUrl() + path,
    method: method,
    headers: allHeaders,
    params: params,
    data: body
  }).then(response =>
    handlerThen(response)
  ).catch(error => {
    try {
      if (isAuthenticated) {//EXPIRE
        let expired
        try {
          expired = ('EXPIRED' == error.response.data.message)
        } catch (err) {
          expired = false
        }
        if (expired) {
          refresh(path, loading, method, headers, isAuthenticated, params, body, handlerThen, handlerCatch)
          return
        }
        if (error && error.response && error.response.status === 412) { //authorization code invalid
          if (error.response.headers['rl']) { //retries left
            showRetriesLeft(error.response.headers['rl'])
          } else if (error.response.headers['tl']) {
            showBlockedUntil(error.response.headers['tl'])
          }
        }
      }
      handlerCatch(error)
    } catch (err2) {
      failWs(error)
    }
  }).finally(function () {
      if (loading) {
        hideLoading()
      }
    }
  )
}
