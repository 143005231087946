// vee-validate.js
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import {isValidCnpj, isValidCpf, moneyToFloat, onlyDigits} from "@/util/core.utils";

extend('required', required);

extend('cpfCnpjValid', {
  message: field => 'O ' + field + ' é inválido.',
  validate: (value) => {
    return isValidCnpj(onlyDigits(value)) || isValidCpf(onlyDigits(value))
  }
});

extend('moneyGreaterThanZero', {
  message: field => 'O ' + field + ' deve ser maior que zero.',
  validate: (value) => {
    return moneyToFloat(value) > 0
  }
});
