<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar navbar-top border-bottom navbar-expand navbar-expand-lg bg- bg-primary navbar-dark">

    <div class="col-md-6">
      <h4 class="text-white float-left text-left mt-2" title="Saldo Disponível">
        <small class="font-weight-normal">SALDO:</small> R$ {{toMoney(balanceAvaliable)}}
        <a href="#" @click="callBalance" class="text-white float-right text-left pl-2">
          <fa-icon class="fas fa-sync" color="#FFF"></fa-icon>
        </a>
      </h4>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div class="pr-3 sidenav-toggler"
             :class="{active: $sidebar.showSidebar, 'sidenav-toggler-dark': type === 'default', 'sidenav-toggler-light': type === 'light'}"
             @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
      <base-dropdown class="nav-item"
                     tag="li"
                     title-classes="nav-link"
                     title-tag="a"
                     icon="ni ni-bell-55"
                     menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden">
        <template>
          <!-- Dropdown header -->
          <div class="px-3 py-3">
            <h6 class="text-sm text-muted m-0">Você possui <strong class="text-primary">0</strong> notificações.</h6>
          </div>
          <!-- List group -->
          <div class="list-group list-group-flush">
          </div>
        </template>

      </base-dropdown>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right
                     class="nav-item"
                     tag="li"
                     title-tag="a"
                     title-classes="nav-link pr-0">
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img :src="loadDocSrc()" v-if="this.showAvatar">
                    <img src="/img/theme/user.png" v-else alt="...">
                  </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{this.nickname()}}</span>
            </div>
          </div>
        </a>

        <template>

          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Bem vindo!</h6>
          </div>
          <a href="/pages/user" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>Minha conta</span>
          </a>
          <!--          <a href="#!" class="dropdown-item">-->
          <!--            <i class="ni ni-settings-gear-65"></i>-->
          <!--            <span>Configurações</span>-->
          <!--          </a>-->
          <div class="dropdown-divider"></div>
          <a href="javascript:void(0)" class="dropdown-item" @click="logout">
            <i class="ni ni-user-run"></i>
            <span>Sair</span>
          </a>

        </template>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
  import {BaseNav} from '@/components'
  import {isNullOrEmpty, toMoney} from "../../util/core.utils"
  import {callWs, failWs, postWs} from "../../ws.service"
  import 'fa-icons';

  export default {
    components: {
      BaseNav
    },
    data() {
      return {
        avatarBase64: '',
        avatarFileType: '',
        showAvatar: false,
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
        balanceAvaliable: 0
      }
    },
    props: {
      type: {
        type: String,
        default: 'default', // default|light
        description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
      }
    },
    created() {
      this.avatarBase64 = localStorage.getItem("avatarBase64")
      this.avatarFileType = localStorage.getItem("avatarFileType")
      if (isNullOrEmpty(this.avatarFileType)) {
        this.showAvatar = false
      } else {
        this.showAvatar = true
      }

      setInterval(() => {
        this.callBalance()
      }, 30000)
    },
    computed: {
      routeName() {
        const {name} = this.$route;
        return this.capitalizeFirstLetter(name);
      }
    },
    mounted() {
      this.callBalance()
      this.callNotificationWs()
    },
    methods: {
      toMoney,
      loadDocSrc() {
        let fileType = this.avatarFileType.replace(" ", "").toLowerCase()
        if (fileType == 'jpg') {
          return 'data:image/jpeg;base64,' + this.avatarBase64
        }
        return 'data:image/' + fileType + ';base64,' + this.avatarBase64
      },
      nickname() {
        let name = '@' + localStorage.getItem("username")
        if (isNullOrEmpty(name)) {
          return ''
        }
        let split = name.split(' ')
        if (split.length == 1) {
          return split[0]
        }
        return split[0] + ' ' + split[split.length - 1]
      },
      logout() {
        localStorage.clear()
        location.reload()
      },
      callBalance() {
        callWs("/balance/get-current", false,
          'POST', null, true, null,
          {
            username: localStorage.getItem("username")
          },
          this.loadBalance,
          failWs
        )
      },
      loadBalance(response) {
        this.balanceAvaliable = response.data
        localStorage.setItem('accountBalance', this.balanceAvaliable)
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      callNotificationWs() {
        callWs("/notification/get-notification", true,
          "POST", null, true, null,
          {notificationStatus: null, notificationMsgType: null},
          this.loadNotifications,
          this.failNotification)
      },
      failNotification(error) {
        return
      },
      loadNotifications(response) {
        let notifications = response.data.notifications
        if (notifications.length <= 0) {
          return
        }
        this.notificationsItems = notifications
        this.activeNotifications = true
      },
      deleteNotificationWs(event, index) {
        this.notificationsItems.splice(index, 1)
        this.updateNotificationsList(event.id, "IGNORE")
      },
      clickNotification() {
        this.updateNotificationRead()
        this.callNotificationWs()
      },
      updateNotificationRead() {
        this.notificationsItems.forEach(value => {
          this.idNotificationMsgAux = ""
          if (value.id == null) {
            this.insertNotificationsGlobal(value)
          } else if (value.notificationStatus != 'READ') {
            this.updateNotificationsList(value.id, "READ")
          } else {
            return
          }
        });
      },
      updateNotificationsList(id, status) {
        callWs("/notification/update-notification", true,
          "POST", null, true, null,
          {idNotification: id, notificationStatus: status, pushStatus: null},
          this.updateNotificationOk,
          null)
      },
      insertNotificationsGlobal(value) {
        callWs("/notification/insert-global-notification", true,
          "POST", null, true, null,
          {idNotificationMsg: value.notificationMsgId, expirationDate: value.expirationDate},
          response => {
            this.insertNotificationOk(response, value)
          },
          null)
      },
      insertNotificationOk(response, value) {
        value.id = response.data
        value.notificationStatus = "READ"
      },
      updateNotificationOk(response) {
        return
      }
    }
  };
</script>
