<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item :link="{
            name: 'Dashboard',
            icon: 'fas fa-home text-orange',
            path: '/dashboard'
          }">
        </sidebar-item>

        <sidebar-item :link="{
                  name: 'Depósito via Boleto',
                  icon: 'fas fa-file-download text-green',
                  path: '/pages/deposit'
                }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Depósito via TED',
                        icon: 'fa fa-arrow-down text-green',
                        path: '/pages/deposit-ted'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Receber PIX',
                        icon: 'PIX_DEPOSIT',
                        path: '/pages/pix-charge'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Enviar PIX',
                        icon: 'PIX_PAYMENT',
                        path: '/pages/pix-transfer'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Enviar TED',
                        icon: 'fa fa-arrow-up text-primary',
                        path: '/pages/ted'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                  name: 'Pagar Boleto',
                  icon: 'fas fa-file-upload text-primary',
                  path: '/pages/payments'
                }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Transf. entre Contas',
                        icon: 'fas fa-exchange-alt text-primary',
                        path: '/pages/tev'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Transações',
                        icon: 'fas fa-tasks text-info',
                        path: '/pages/transactions'
                      }">
        </sidebar-item>

        <sidebar-item :link="{
                        name: 'Limites e Tarifas',
                        icon: 'fas fa-poll text-info',
                        path: '/pages/fees-and-limits'
                      }">
        </sidebar-item>

        <br v-if="docsOk == 'false'"/>

        <sidebar-item v-if="docsOk == 'false'" :link="{
                        name: 'COMPLETAR CADASTRO',
                        icon: 'fas fa-tasks text-warning',
                        path: '/pages/user'
                      }" style="margin-top: 16px">
        </sidebar-item>

      </template>
      <template slot="links-after" v-if="manageApiAccess || accessClientManagement || accessRoles || limitesGlobais || tickets">
        <hr class="my-3">
        <ul class="navbar-nav mb-md-3">
          <sidebar-item :link="{name: 'Acesso via API', icon: 'fas fa-plug', path: '/pages/manage-api-access'}"></sidebar-item>
          <sidebar-item v-if="accessClientManagement" :link="{name: 'Cadastro de Clientes', icon: 'ni ni-books'}">
            <sidebar-item
              :link="{name: 'Natureza Física', icon: 'ni ni-settings-gear-65', path: '/bo/account/search/natural'}"></sidebar-item>
            <sidebar-item
              :link="{name: 'Natureza Jurídica', icon: 'ni ni-settings-gear-65', path: '/bo/account/search/legal'}"></sidebar-item>
          </sidebar-item>
          <sidebar-item v-if="accessRoles" :link="{name: 'Configurações de Sistema', icon: 'ni ni-settings-gear-65'}">
            <sidebar-item v-if="accessRoles" :link="{name: 'Permissões de Acesso', icon: 'ni ni-settings-gear-65'}">
              <sidebar-item v-if="manageRoles" :link="{ name: 'Gerenciar Roles', path: '/bo/roles/manageroles' }"/>
              <sidebar-item v-if="insertRole" :link="{ name: 'Inserir Role', path: '/bo/roles/registerrole' }"/>
              <sidebar-item v-if="managePacksRole" :link="{ name: 'Gerenciar Packs', path: '/bo/roles/managepacks' }"/>
              <sidebar-item v-if="insertRolePack" :link="{ name: 'Inserir Pack', path: '/bo/roles/registerrolepack' }"/>
              <sidebar-item v-if="manageUsersRole" :link="{ name: 'Gerenciar Usuários', path: '/bo/roles/manageusers' }"/>
            </sidebar-item>
            <sidebar-item v-if="limitesGlobais" :link="{name: 'Limites', icon: 'ni ni-settings-gear-65' }">
              <sidebar-item v-if="limitesGlobais" :link="{ name: 'Limites Financeiros Globais', path: '/bo/operation-limit/edit' }"/>
            </sidebar-item>

          </sidebar-item>
          <sidebar-item v-if="listTickets" :link="{name: 'Chamadas', icon: 'ni ni-chat-round', path: '/bo/customer-tickets/list'}"></sidebar-item>
          <sidebar-item v-if="pixPoolMngr" :link="{name: 'Pool de Pix', icon: 'ni ni-ruler-pencil', path: '/bo/pix-pool/'}"></sidebar-item>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import {FadeTransition} from 'vue2-transitions';
  import {isAuthorized, isNotAuthorized, isNotSomeAuthorization, isSomeAuthorization} from '../../util/role.utils';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    data() {
      return {
        docsOk: localStorage.getItem('docsOk'),
        accessClientManagement: isAuthorized('BO_LIST_ACCOUNT'),
        accessRoles: isSomeAuthorization(['BO_ROLES', 'INSERT_ROLE', 'INSERT_ROLE_PACK', 'MANAGE_USERS_ROLE', 'MANAGE_ROLES']),
        limitesGlobais: isAuthorized('BO_LIST_GLOBAL_OP_LIMITS'),
        tickets: isSomeAuthorization(['BO_CUSTOMER_TICKET_LIST']),
        pixPoolMngr: isSomeAuthorization(['BO_PIX_POOLED_MNGR']),
        manageApiAccess: isAuthorized('GENERATE_API_ACCESS'),

        insertRole: isAuthorized('INSERT_ROLE'),
        insertRolePack: isAuthorized('INSERT_ROLE_PACK'),
        manageUsersRole: isAuthorized('MANAGE_USERS_ROLE'),
        managePacksRole: isAuthorized('MANAGE_PACKS_ROLE'),
        manageRoles: isAuthorized('MANAGE_ROLES'),

        listTickets: isAuthorized('BO_CUSTOMER_TICKET_LIST')
      }
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
</style>
