import {showLoading, hideLoading} from './util/loading.utils'

const loadingShowDelay = 100;
let routeResolved = false;

function tryInit() {
  routeResolved = false
  setTimeout(() => {
    if (!routeResolved) {
      showLoading()
    }
  }, loadingShowDelay)
}

export default function initLoading(router) {
  router.beforeEach((to, from, next) => {
    tryInit()
    return next()
  });

  router.afterEach(() => {
    // Complete the animation of the route loading.
    routeResolved = true;
    hideLoading()
  });
}
