import {failWs, postWs} from "@/ws.service";
import Vue from 'vue';

async function saveCode(codeToSave) {
  await postWs("/auth/authorization-code",
    true,
    null,
    {code: codeToSave},
    (response) => {
      localStorage.setItem('n', 'y');
    },
    failWs)
}

async function setSecurityCode() {
  setTimeout(() => {
    document.getElementById('code1').focus()
  }, 600)

  let result = await Vue.swal({
    title: 'Código de Segurança',
    html:
      '<span class="center">Cadastre um código numérico de 4 dígitos para executar operações financeiras em sua conta. Memorize este código pois ele será requisitado outras vezes.</span>' +
      '<span>' +
      ' <div class="form-group mt-4" style="margin-bottom: 0 !important">' +
      '   <label class="form-control-label"> Código Numérico </label>' +
      '     <div class="has-label">' +
      '       <input type="password" id="code1" placeholder="****" maxlength="4" class="form-control col-md-4 text-center m-auto"><!---->' +
      '     </div>' +
      ' </div>' +
      '</span>' +
      '<br>' +
      '<span>' +
      ' <div class="form-group">' +
      '   <label class="form-control-label"> Confirme o Código </label>' +
      '     <div class="has-label">' +
      '       <input type="password" id="code2" placeholder="****" maxlength="4" class="form-control col-md-4 text-center m-auto"><!---->' +
      '     </div>' +
      ' </div>' +
      '</span>' +
      '<br>' +
      '<span id="error" class="text-red text-sm-center"></span>',
    buttonsStyling: false,
    confirmButtonText: 'CONTINUAR',
    cancelButtonText: 'cancelar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill'
    },
    showConfirmButton: true,
    showCancelButton: true,
    preConfirm: () => {
      if (document.getElementById('code1').value !== document.getElementById('code2').value) {
        document.getElementById('error').innerText = "As senhas não conferem."
        return false;
      }
      if (!(/^[0-9]{4}$/.test(document.getElementById('code1').value))) {
        document.getElementById('error').innerText = "A senha deve ser numérica e de 4 dígitos."
        return false;
      }
      return document.getElementById('code1').value
    },
    icon: 'warning'
  })

  if (result.isConfirmed && result.value) {
    await saveCode(result.value)
    return true
  } else {
    return false
  }
}

async function askCode() {
  setTimeout(() => {
    document.getElementById('code').focus()
  }, 600)

  let result = await Vue.swal({
    title: 'Código de Segurança',
    html:
      '<span class="center">Informe seu código numérico para prosseguir:</span>' +
      '<span>' +
      ' <div class="form-group mt-4" style="margin-bottom: 0 !important">' +
      '     <div class="has-label">' +
      '       <input type="password" id="code" placeholder="****" maxlength="4" autocomplete="false" class="form-control col-md-4 text-center m-auto"><!---->' +
      '     </div>' +
      ' </div>' +
      '</span>' +
      '<br>' +
      '<span id="error" class="text-red text-sm-center"></span>',
    buttonsStyling: false,
    confirmButtonText: 'CONTINUAR',
    cancelButtonText: 'cancelar',
    customClass: {
      confirmButton: 'btn btn-success btn-fill',
      cancelButton: 'btn btn-danger btn-fill'
    },
    showConfirmButton: true,
    showCancelButton: true,
    icon: 'question',
    preConfirm: () => {
      if (!(/^[0-9]{4}$/.test(document.getElementById('code').value))) {
        document.getElementById('error').innerText = "A senha deve ser numérica e de 4 dígitos."
        return false;
      }
      return document.getElementById('code').value
    },
  });

  if (result.isConfirmed && result.value) {
    return result.value;
  } else {
    return null;
  }
}

export async function getNumericSecurityCode() {
  if (localStorage.getItem('n') !== 'y') {
    let ask = await setSecurityCode();
    if (ask) {
      return await askCode();
    } else {
      return null
    }
  }
  let code = await askCode()
  return code
}

export function showRetriesLeft(retriesLeft) {
  Vue.swal({
    title: 'Aviso!',
    text: 'O código digitado está inválido. Tentativas restantes: ' + retriesLeft,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-warning btn-fill'
    },
    icon: 'warning'
  })
}

export function showBlockedUntil(unti) {
  Vue.swal({
    title: 'Aviso!',
    text: 'Seu código de autorização está bloqueado devido à excesso de tentativas inválidas. Tente novamente após ' + unti,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'btn btn-warning btn-fill'
    },
    icon: 'warning'
  })
}
