import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Dashboard pages
const Dashboard = () => import('@/views/Dashboard/Dashboard.vue');

// Pages
const User = () => import('@/views/UserProfile/UserProfile.vue');
const UploadDoc = () => import('@/views/FileUpload/UploadDoc.vue');
const Login = () => import('@/views/Pages/Login.vue');
const Register = () => import('@/views/Pages/Register.vue');
const Recovery = () => import('@/views/Pages/Recovery.vue');
const Forgot = () => import('@/views/Pages/Forgot.vue');
const Transactions = () => import('@/views/Report/Transactions.vue');
const Deposit = () => import('@/views/Deposit/BankSlipDeposit.vue');
const DepositTed = () => import('@/views/Deposit/TedDeposit.vue');
const Tev = () => import('@/views/Transfer/Tev.vue');
const Ted = () => import('@/views/Transfer/Ted.vue');
const Payments = () => import('@/views/Transfer/BankSlipPayment.vue');
const PixTransfer = () => import('@/views/Transfer/PixTransfer.vue');
const PixCharge = () => import('@/views/Transfer/PixCharge.vue');
const FeesAndLimits = () => import( '@/views/Report/FeesAndLimits.vue');
const ShowPdf = () => import( '@/views/Pdf/ShowPdf.vue');
const GetPixQrCodePdf = () => import( '@/views/Pdf/GetPixQrCodePdf.vue');
const ConfigureApiAccess = () => import( '@/views/Api/ConfigureApiAccess');

//Backoffice
const BoAccountSearchNatural = () => import( '@/views/BackOffice/Account/Search.vue')
const BoAccountSearchLegal = () => import( '@/views/BackOffice/Account/Search.vue')
const BoAccountEdit = () => import( '@/views/BackOffice/Account/Edit.vue')
const BoOperationLimitGlobal = () => import( '@/views/BackOffice/OperationLimit/GlobalOperationLimit.vue')
const BoOperationLimitUser = () => import( '@/views/BackOffice/OperationLimit/UserOperationLimit.vue')
const BoPixPool = () => import( '@/views/BackOffice/PixPool/Search.vue')

const BoRegisterRole = () => import( '@/views/BackOffice/Role/RegisterRole.vue')
const BoRegisterRolePack = () => import( '@/views/BackOffice/Role/RegisterRolePack.vue')
const BoManageUsersRole = () => import( '@/views/BackOffice/Role/ManageUsers.vue')
const BoManagePacksRole = () => import( '@/views/BackOffice/Role/ManagePacks.vue')
const BoManageRoles = () => import( '@/views/BackOffice/Role/ManageRoles.vue')
const BoCustomerTickets = () => import( '@/views/BackOffice/Ticket/ManageTickets')


// AUTH VERIFICATION ------------------------------------------
const state = {
  isAuthenticated: localStorage.getItem('isAuth') == 'true'
    && localStorage.getItem('ut').startsWith("$b")
};

const ifNotAuthenticated = (to, from, next) => {
  if (!state.isAuthenticated) {
    next()
    return
  }
  next("/dashboard");
};

const ifAuthenticated = (to, from, next) => {
  if (state.isAuthenticated) {
    next()
    return
  }
  next("/login")
};
//-------------------------------------------------------------
let login = {
  path: '/login',
  name: 'Login',
  component: Login,
  beforeEnter: ifNotAuthenticated
};

let forgotPage = {
  path: '/forgot',
  name: 'Forgot',
  component: Forgot,
  beforeEnter: ifNotAuthenticated
}

let register = {
  path: '/register',
  name: 'Register',
  component: Register,
  beforeEnter: ifNotAuthenticated
};

let recoveryPage = {
  path: '/recovery/:key',
  name: 'Recovery',
  component: Recovery,
  beforeEnter: ifNotAuthenticated
}

let getPixQrCodePdf = {
  path: '/pdf/pix/qr-code/:uuid',
  name: "PDF QrCode Pix",
  component: GetPixQrCodePdf,
  beforeEnter: ifNotAuthenticated
}

let dashboard = {
  path: '/',
  component: DashboardLayout,
  redirect: '/dashboard',
  children: [
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: ifAuthenticated
    }
  ]
}

let uploadDoc = {
  path: '/user/profile',
  component: DashboardLayout,
  redirect: '/user/profile/uploaddoc',
  children: [
    {
      path: 'uploaddoc',
      name: 'UploadDoc',
      component: UploadDoc,
      beforeEnter: ifAuthenticated
    }
  ]
}

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  name: 'Pages',
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'transactions',
      name: 'Transactions',
      component: Transactions,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'tev',
      name: 'Tev',
      component: Tev,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'deposit',
      name: 'Deposit',
      component: Deposit,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'deposit-ted',
      name: 'DepositTed',
      component: DepositTed,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'ted',
      name: 'Ted',
      component: Ted,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'pix-transfer',
      name: 'PixTransfer',
      component: PixTransfer,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'pix-charge',
      name: 'PixCharge',
      component: PixCharge,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'payments',
      name: 'Payments',
      component: Payments,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'fees-and-limits',
      name: 'FeesAndLimits',
      component: FeesAndLimits,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'show-pdf',
      name: 'ShowPdf',
      component: ShowPdf,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'manage-api-access',
      name: 'manageApiAccess',
      component: ConfigureApiAccess,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boAccount = {
  path: '/bo/account',
  component: DashboardLayout,
  redirect: '/bo/account/search',
  children: [
    {
      path: 'search/natural',
      name: 'BoAccountSearchNatural',
      component: BoAccountSearchNatural,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'search/legal',
      name: 'BoAccountSearchLegal',
      component: BoAccountSearchLegal,
      beforeEnter: ifAuthenticated
    },
    {
      path: 'edit/:username',
      name: 'BoAccountEdit',
      component: BoAccountEdit,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boOperationLimitGlobal = {
  path: '/bo/operation-limit',
  component: DashboardLayout,
  redirect: '/bo/operation-limit/edit',
  children: [
    {
      path: 'edit',
      name: 'BoOperationLimitGlobal',
      component: BoOperationLimitGlobal,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boRegisterRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/registerrole',
  children: [
    {
      path: 'registerrole',
      name: 'BoRegisterRole',
      component: BoRegisterRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boRegisterRolePack = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/registerrolepack',
  children: [
    {
      path: 'registerrolepack',
      name: 'BoRegisterRolePack',
      component: BoRegisterRolePack,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageUsersRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/manageusers',
  children: [
    {
      path: 'manageusers',
      name: 'BoManageUsersRole',
      component: BoManageUsersRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManagePacksRole = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/managepacks',
  children: [
    {
      path: 'managepacks',
      name: 'BoManagePacksRole',
      component: BoManagePacksRole,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boManageRoles = {
  path: '/bo/roles',
  component: DashboardLayout,
  redirect: '/bo/roles/manageroles',
  children: [
    {
      path: 'manageroles',
      name: 'BoManageRoles',
      component: BoManageRoles,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boOperationLimitUser = {
  path: '/bo/user-operation-limit',
  component: DashboardLayout,
  redirect: '/bo/user-operation-limit/edit',
  children: [
    {
      path: 'edit/:username',
      name: 'BoOperationLimitUser',
      component: BoOperationLimitUser,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boPixPool = {
  path: '/bo/pix-pool',
  component: DashboardLayout,
  redirect: '/bo/pix-pool/manage',
  children: [
    {
      path: 'manage',
      name: 'BoPixPool',
      component: BoPixPool,
      beforeEnter: ifAuthenticated
    }
  ]
}

let boCustomerTickets = {
  path: '/bo/customer-tickets',
  component: DashboardLayout,
  redirect: '/bo/customer-tickets',
  children: [
    {
      path: 'list',
      name: 'BoCustomerTickets',
      component: BoCustomerTickets,
      beforeEnter: ifAuthenticated
    }
  ]
}

const routes = [
  login,
  register,
  dashboard,
  pagesMenu,
  boAccount,
  boOperationLimitGlobal,
  boRegisterRole,
  boRegisterRolePack,
  boPixPool,
  boManageUsersRole,
  boManagePacksRole,
  boManageRoles,
  boOperationLimitUser,
  forgotPage,
  recoveryPage,
  uploadDoc,
  boCustomerTickets,
  getPixQrCodePdf,
  {path: '*', component: NotFound}
]

export default routes
